/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  box-sizing: border-box;
  display: inline-block;
  width: 400px;     
  height: 450px;
  box-shadow: 0px 0px 15px -5px;
  background-color: rgba(238, 239, 250, 0.3);
  border: 40px;
  border-top-color: black;
  border-radius: 20px;
  transform: scale(0.9);
  transition: all ease 0.2s;
  overflow: hidden;

  @media (max-width: 500px) {
    width: 150px;
    height: 180px;   
  };
  &:hover {
    transform: scale(1);
    background-color: rgba(238, 239, 250, 0.6);    
  };
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: 23px;
    width: 90%;
    height: 39%;
  };
`;
const Título = styled.header`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 30px;
  `;

const Details = styled.span`
display: block;
width: 90%;
height: 37%;
border-bottom: 2px solid #444444;
margin-left: auto;
margin-right: auto;
span {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
};
@media (max-width: 505px) {
    visibility: hidden; 
  };
`;

function CountryCard({ countryData }) {
  const {
    name, nativeName = 'null', population = 'null', area = 'null', currencies = false, flags = [],
  } = countryData;
  const mi = (people) => String(parseInt((parseInt(people, 10) / 1000), 10));

  return (
    <Card>
      <img src={flags.png} alt={nativeName} />
      <Título>
        {name}
      </Título>
      <Details>
        <span>
          <p>População</p>
          <p>{`${mi(population)} Mil`}</p>
        </span>
        <span>
          <p>Área Geográfica</p>
          <p>{`${area} km²`}</p>
        </span>
        <span>
          <p>Moeda</p>
          <p>{currencies ? `(${currencies[0].symbol})  ${currencies[0].name}` : ' '}</p>
        </span>
      </Details>
    </Card>

  );
}

export default CountryCard;
