import axios from 'axios';

const api = async (url = 'lang/pt') => {
  const middleData = await axios({
    baseURL: `https://restcountries.com/v2/${url}`,

  });
  return middleData.data;
};

export default api;
