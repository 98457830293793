/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';

const StyHead = styled.div`
display: flex;
width: 95%;
margin: auto;
margin-top: 25px;
`;

const Search = styled.input`
    background: url(https://cdn0.iconfinder.com/data/icons/slim-square-icons-basics/100/basics-19-32.png) no-repeat 0px 5px;
    background-size: 30px;
    font-size: 19px;
    width: 100%;
    border: transparent;
    border-bottom: solid 1px #ccc;
    padding: 10px 10px 10px 30px;
    outline: none;
 `;

function SearchBar({ countries, setFilter }) {
  const [searchingName, setSearchingName] = useState('');

  const handleChange = ({ target: { value } }) => {
    setSearchingName(value);

    const midCountriesFilter = countries.filter(
      ({ name }) => (name.toLowerCase().includes((value.toLowerCase()))),
    );
    setFilter(midCountriesFilter);
  };

  return (
    <StyHead>
      <Search
        type="text"
        value={searchingName}
        onChange={handleChange}
        placeholder="Filtre pelo nome"
      />
    </StyHead>
  );
}

export default SearchBar;
