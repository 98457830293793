import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import SearchBar from '../components/SearchBar';
import CountryCard from '../components/CountryCard';
import Api from '../services/APIs';
import imgBackground from '../art/back.jpg';

const GlobalStyle = createGlobalStyle`
  body {
    background-image: url(${imgBackground});
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: top;
  }`;

const Panel = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-around;
width: 100%;
`;

function Home() {
  const [countries, setCountries] = useState([]);
  const [countriesFilter, setCountriesFilter] = useState([]);
  useEffect(() => {
    Api().then((countriesData) => {
      setCountries(countriesData);
      setCountriesFilter(countriesData);
    });
  }, []);

  return (
    <>
      <GlobalStyle />
      <SearchBar
        countries={countries}
        countriesFilter={countriesFilter}
        setFilter={setCountriesFilter}
      />
      <Panel>
        {countriesFilter.map((country) => (<CountryCard countryData={country} key={uuidv4()} />))}
      </Panel>
    </>
  );
}

export default Home;
